.src-components-___App__loader-wrapper___2iMkN {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
body {
  margin: 0;
}

